import React from "react";
import { Button, Divider, Space } from "antd";
import { Layout, Tabs } from "antd";
import { Content, Footer } from "antd/es/layout/layout";
import Services from "./Services";
import UserData from "./UserData";
import Consents from "./Consents";
import RegisterGroup from "./RegisterGroup";
import Messages from "./Messages";
import Credentials from "./Credentials";
import { cleanUrl } from "../util/cleanUrl";
import { Context } from "../context/context";
import Fixes from "./Fixes";

interface StartProps {
  unauthorized?: boolean;
}

export default function Start({ unauthorized }: StartProps) {
  // spies only work if the function is called directly from the parent object
  // so for testing we can't desctructure useContext
  const { config, keycloak, isAdmin } = React.useContext(Context);
  const authenticated = keycloak?.authenticated;
  const { env, buildInfo } = config;

  const tabItems = [
    {
      title: "Services",
      content: <Services />,
    },
    {
      title: "User Data",
      content: <UserData />,
    },
    {
      title: "Credentials",
      content: <Credentials />,
    },
    {
      title: "Consents",
      content: <Consents />,
    },
    {
      title: "Register Group",
      content: <RegisterGroup />,
    },
    {
      title: "Messages",
      content: <Messages />,
    },
    {
      title: "Fixes & Maintenance",
      content: <Fixes />,
    },
  ];

  const login = () => {
    const url = cleanUrl();
    keycloak?.login({ redirectUri: url });
  };

  const loggedOutContainer = (
    <Space direction="vertical" size={["middle", "middle"]}>
      <Layout>
        <Divider style={{ whiteSpace: "normal", textAlign: "left" }}>
          <p>Welcome to the dashboard of Teamedio!</p>
          <p>
            Here you can find information about running systems and deployments
            and manage data of users and organizations.
          </p>
          <p>You must be an admin to proceed.</p>
        </Divider>
        {unauthorized ? (
          <Divider>
            <Divider>
              <p className="error-message">
                You are not authorized to access this page.
              </p>
            </Divider>
            <Divider>
              <Button
                id="back-to-login-button"
                type="primary"
                htmlType="button"
                onClick={login}
              >
                Back to Login
              </Button>
            </Divider>
          </Divider>
        ) : (
          <Divider>
            <Button
              id="login-button"
              type="primary"
              htmlType="button"
              onClick={login}
            >
              Login
            </Button>
          </Divider>
        )}
        <Footer className="Footer">
          <div>
            <div>Name: </div>
            <div>{buildInfo?.name}</div>
          </div>
          <div>
            <div>Environment: </div>
            <div>{env}</div>
          </div>
          <div>
            <div>Version: </div>
            <div>{buildInfo?.version}</div>
          </div>
          <div>
            <div>Build Date: </div>
            <div>{buildInfo?.build?.date}</div>
          </div>
        </Footer>
      </Layout>
    </Space>
  );

  const loggedInContainer = (
    <Layout>
      <Content>
        <Tabs
          defaultActiveKey="User Data"
          className="Tabs"
          style={{ maxWidth: "100%" }}
          items={tabItems.map((tabItem) => {
            return {
              key: tabItem.title,
              label: tabItem.title,
              children: tabItem.content,
            };
          })}
        />
      </Content>
    </Layout>
  );

  const getPage = () => {
    if (authenticated && isAdmin) {
      return loggedInContainer;
    } else {
      return loggedOutContainer;
    }
  };

  return getPage();
}
