import { Button, Divider, Form, Input, Table } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../context/context';
import { fixMissingCasesDto } from '../context/api';
import { jsonSyntaxHighlight } from '../util/text';

export default function FixMissingCase() {
  const { api } = useContext(Context);

  const [pmId, setPmId] = useState<string>();
  const [fhirId, setFhirId] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [disabled, setDisabled] = useState(true);

  const [error, setError] = useState<string>();
  const [result, setResult] = useState<fixMissingCasesDto[]>([]);

  useEffect(() => {
    setDisabled(!pmId && !fhirId);
  }, [pmId, fhirId])

  async function fixEntry() {
    if (!api) return;

    try {
      const results = await api.fixPatientWithoutCases(pmId, fhirId, { title, description })
      setResult(results)
    } catch (e: any) {
      console.log(e)
      setError(e?.response?.statusText)
    }
  }
  return (
    <div>
      <Divider><h4>Add missing cases</h4></Divider>
      <div style={{ display: 'flex', gap: 10 }}>
        <div style={{ padding: 10 }}>
          <Form.Item
            label="pmId"
            className="user-input"
            colon
          >
            <Input
              placeholder='May be a comma separated list'
              style={{ minWidth: 300 }}
              onChange={(e) => setPmId(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label="fhirId"
            className="user-input"
            colon
          >
            <Input
              placeholder='May be a comma separated list'
              style={{ minWidth: 300 }}
              onChange={(e) => setFhirId(e.target.value)}
            />
          </Form.Item>
          <div style={{ fontSize: '80%', color: '#999' }}> You may use both fields at the same time</div>
        </div>
        <div style={{ backgroundColor: '#eee', padding: 10, borderRadius: 5 }}>
          <Form.Item
            label="title"
            className="user-input"
            colon
          >
            <Input
              placeholder='Nierentransplantation'
              style={{ minWidth: 300 }}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label="description"
            className="user-input"
            colon
          >
            <Input
              placeholder='SmartNTX'
              style={{ minWidth: 300 }}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Item>
        </div>
      </div>
      <Button type='primary' onClick={fixEntry} disabled={disabled}>Fix</Button>
      <div style={{ color: 'red' }}>{error && <>Fix failed: {error}</>}</div>
      <div>
        {result.length > 0 && <div>
          Got {result.length} result(s).
          <Table dataSource={result} columns={[
            { dataIndex: 'patientPmId', title: 'pmId' },
            { dataIndex: 'patientFhirId', title: 'fhirId' },
            {
              key: 'response.msg', title: 'message', render(value, record) {
                return record.response.msg;
              },
            },
            {
              key: 'response', title: 'response', render(value, record) {
                return <div style={{ whiteSpace: "pre" }}
                  dangerouslySetInnerHTML={{ __html: jsonSyntaxHighlight(record.response) }}></div>
              },
            }
          ]} />

        </div>}
      </div>
    </div>
  )
}
