import React, { useState, useEffect, createContext, ReactNode } from "react";
import { getKeycloak } from "./keycloak";
import { getConfig, ConfigType } from "../config/config";
import { BackendApi } from "./api";

// Define types for your context
interface ContextType {
  keycloak: {
    authenticated: boolean;
    login: (options: { redirectUri: string }) => any;
    logout: (options: { redirectUri: string }) => any;
  };
  config: ConfigType;
  isAdmin: boolean;
  api: BackendApi | undefined;
  errorMsg: string;
  setErrorMsg: (msg: string) => void
}

// Create the context with an initial value
export const Context = createContext<ContextType>({
  keycloak: {
    authenticated: false,
    login: ({ redirectUri }: { redirectUri: string }) => {
      // Placeholder function; actual implementation provided later
    },
    logout: ({ redirectUri }: { redirectUri: string }) => {
      // Placeholder function; actual implementation provided later
    },
  },
  config: {
    keycloak: {
      url: "",
      realm: "",
      clientId: ""
    },
    url: {
      admin: "",
      message: "",
    },
  },
  isAdmin: false,
  api: undefined,
  errorMsg: "",
  setErrorMsg: () => { }
});

// Define types for the props of your ContextWrapper component
interface ContextWrapperProps {
  children: ReactNode;
}

export function ContextWrapper({ children }: ContextWrapperProps) {
  const [config, setConfig] = useState<any>({});
  const [keycloak, setKeycloak] = useState<any>({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [api, setApi] = useState<any>({});
  const [errorMsg, setErrorMsg] = useState<string>("");


  useEffect(() => {
    let cfg: ConfigType;

    getConfig()
      .then((conf) => {
        setConfig(conf);
        cfg = conf;
        const getKeycloakResult = getKeycloak(conf);
        return getKeycloakResult;
      })
      .then((getKeycloakResult) => {
        setKeycloak(getKeycloakResult.keycloak);
        setIsAdmin(getKeycloakResult.isAdmin);
        if (getKeycloakResult.keycloak) {
          setApi(new BackendApi({ keycloak: getKeycloakResult.keycloak, url: cfg.url.admin }))
        }
      });
  }, []);

  return (
    <Context.Provider value={{ config, keycloak, isAdmin, api, errorMsg, setErrorMsg }}>
      {children}
    </Context.Provider>
  );
}
