import { Button, Checkbox, Divider, Table } from "antd"
import { useContext, useState } from "react"
import { Context } from "../context/context";
import { NoCasesDto } from "../context/api";

const COLUMNS = [
  {
    title: 'PM ID',
    dataIndex: 'pmId',
  },
  {
    title: 'Fhir ID',
    dataIndex: 'fhirId',
  },
];

export default function MissingCases() {
  const { api, setErrorMsg } = useContext(Context);
  const [missingList, setMissingList] = useState<NoCasesDto[]>([])
  const [showFhirID, setShowFhirID] = useState(false)
  function findMissingCases() {
    api?.getValidUsersWithoutCases(showFhirID).then(r => setMissingList(r)).catch(r => setErrorMsg(r))
  }

  return <div>
    <Divider><h4>List patient with no cases</h4></Divider>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <Button type="primary" htmlType="button" onClick={findMissingCases}>
          Find Missing
        </Button>
        <Checkbox
          style={{ marginLeft: 10 }}
          checked={showFhirID}
          onChange={v => setShowFhirID(v.target.checked)}
        >Get also Fhir ID</Checkbox>
      </div>
      {missingList.length > 0 && <div>total: {missingList.length}</div>}
    </div>
    {missingList.length ? <div >
      <Table dataSource={missingList} columns={COLUMNS} />

    </div>
      : <div></div>
    }
  </div>
}

