import React, { PureComponent, useContext, useEffect, useState } from 'react'
import { Context } from '../context/context';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Button, Switch } from 'antd';

interface Data {
  month: number;
  year: number;
  organization_id: string;
  organization_name: string;
  count: number;
}

const stringToColour = (str: string) => {
  let hash = 0;
  str.split('').forEach(char => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
  })
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += value.toString(16).padStart(2, '0')
  }
  return colour
}


export default function ConsentsGraph() {
  const { api } = useContext(Context);

  const [showAll, setShowAll] = useState(false);
  const [selectedDataset, setSelectedDataset] = useState<string>();
  const [highlightDataset, setHighlightDataset] = useState<string>();
  const [data, setData] = useState<any[]>([]);
  const [orgs, setOrgs] = useState<{id:string, name:string}[]>([]);
  const [topOrgsFilter, setTopOrgsFilter] = useState<{[k:string]:boolean}>({});




  useEffect(() => {
    api?.getConsentCounts({ }, { groupBy: { organization: true, month: true, year:true } }).then((res:Data[]) => {
      const byOrg:{[k:string]:Data[]} = {};
      const byDate:{[k:string]:Data[]} = {};
      res.forEach(e=>{
        const key = `${e.year}-${e.month}`;
        let orgs = byOrg[e.organization_id];
        if (!orgs) orgs = byOrg[e.organization_id] = [];
        orgs.push(e);

        let dates = byDate[key];
        if (!dates) dates = byDate[key] = [];
        dates.push(e);
      })

      const sums = Object.entries(byOrg).map(([org_id, entries])=>({org_id, count: entries.reduce((acc, e)=>acc+e.count, 0)}))
      sums.sort((a,b)=>b.count-a.count);
      const topOrgs = Object.fromEntries(sums.slice(0,5).map(e=>[e.org_id, true]));
      setTopOrgsFilter(topOrgs)


      const graphData = Object.entries(byDate).map(([key, entries])=>{
        const countsByOrg:{[k:string]:{count: number, id:string, name:string}} = {}
        entries.forEach(e => {
          countsByOrg[e.organization_id] = {count:e.count, id: e.organization_id, name: e.organization_name};
        });

        return {
          key,
          year: entries[0].year,
          month: entries[0].month,
          orgs: countsByOrg
        }
      }).sort((a,b)=>a.key<b.key ? -1 : 1)

      // keep in order for later display
      setOrgs(sums.map(o=>{const data = byOrg[o.org_id]; return {id:o.org_id, name:data[0].organization_name}}));
      setData(graphData);
    });

  }, [])

  function lineCharts() {
    if (!orgs || orgs.length == 0) return;
    
    return orgs.map(o=>{
      if (!showAll && !topOrgsFilter[o.id]) return;

      const dataKey = `orgs.${o.id}.count`;
      const selected = (!selectedDataset || selectedDataset == dataKey);
      const highlighted = (highlightDataset == dataKey);
      return <Line type="monotone" dataKey={dataKey} hide={!selected} stroke={highlighted ? '#f66': stringToColour(o.id)} 
      name={`(${o.id}) ${o.name}`}
        label={
        ( { x, y, stroke, value }: any ) => {
          return (
            <text x={x} y={y} dy={-4} fill={highlighted ? '#f66':'#666'} fontSize={10} textAnchor="middle">
              {value}
            </text>
          );
        }
      }/>
    }
    )
  }


  //setData([{year: 2024, organization:1, month:1, count:23}])
  return (
    <div style={{display: 'flex', gap: '10px'}}>
    <LineChart width={800} height={400} data={data}>
      {lineCharts()}
      <CartesianGrid stroke="#dddddd" strokeDasharray="5 5" />
      <XAxis dataKey="key" />
      <YAxis />
      <Tooltip />
      <Legend onClick={line=>selectedDataset == line.dataKey ? setSelectedDataset(undefined) : setSelectedDataset(line.dataKey as string)}
        onMouseEnter={line=>setHighlightDataset(line.dataKey as string)} 
        onMouseLeave={()=>setHighlightDataset(undefined)}/>
    </LineChart>
    <div>
      <Switch onChange={(c)=>setShowAll(c)}></Switch> Show All
    </div>
    </div>
  )
}
