import React, { useEffect, useState } from 'react'
import { Case, User } from '../requests/requests';
import { Context } from '../context/context';
import { Table } from 'antd';


// function toClipboard(text:string) {
//   navigator.clipboard.writeText(text)
// }

function UserConsents({ user }: { user?: User }) {
  const { api } = React.useContext(Context);

  const [consent, setConsent] = useState<any[]>([])
  const [error, setError] = useState<string>()

  // orgId in uuid
  const [casesByOrgId, setCasesByOrgId] = useState<{ [key: string]: Case[] }>({});

  function displayConsentsTable() {
    const columns = [
      {
        title: "Organization",
        dataIndex: "org",
        key: "org",
        render: (text: string, item: any) => <>{item.orgId}<br></br>({item.fhirOrgId}) {text}</>,
      },
      {
        title: "Consent status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Consent Id",
        dataIndex: "consentId",
        key: "consentId",
      },
      {
        title: "Cases",
        key: "cases",
        dataIndex: "cases",
        //render: (cases:any[]) => cases?.length || 0,
      },
    ];

    const values = consent.map((consent: any) => {
      return {
        consentId: consent.consentId,
        org: consent.orgName
          ? consent.orgName
          : "-",
        status: consent.status || "active",
        fhirOrgId: consent.fhirOrgId,
        orgId: consent.orgId,
        cases: consent.cases?.length,
        key: consent.consentId,
      };
    });
    return (<>
      {consent.length > 0 ?
        <Table columns={columns} dataSource={values} pagination={false} />
        : <div>No Consents</div>}
    </>

    );
  }

  useEffect(() => {
    if (!user || !api) return;

    const casesByOrgId: { [key: string]: Case[] } = {}
    user.pm?.cases?.forEach(c => c.organizations.forEach(o => {
      let cases = casesByOrgId[o.organizationId];
      if (!cases) cases = casesByOrgId[o.organizationId] = []
      cases.push(c);
    }));
    setCasesByOrgId(casesByOrgId);

    api.getConsentsByUser(user)
      .then((data: any) => {
        api.OrgIds(data.map((d: any) => d.fhirOrgId)).then((fhirToId: any) => {
          // adding cases
          data.forEach((d: any) => {
            d.orgId = fhirToId[d.fhirOrgId];
            d.cases = casesByOrgId[d.orgId];
          })
          setConsent(data);
        })

      }).catch(e => {
        setError(JSON.stringify(e?.response.data));
        console.log('error', e);
      });
  }, [user])
  return (
    <>
      {error ? <>{error}</> :
        consent ? displayConsentsTable() : 'Retrieving consents...'
      }
    </>
  )
}

export default UserConsents